import React from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import { useIdentityContext } from 'react-netlify-identity-widget'

import Spinner from './shared/Spinner'

const Page = styled.section({
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  position: 'relative',
  alignItems: 'flex-start',
})

const Form = styled.div({
  position: 'relative',
  top: '40%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const PasswordInput = styled.input({
  width: 356,
  height: 48,
  border: '1px solid #000000',
  boxSizing: 'border-box',
  borderRadius: '9px',
  outline: 'none',
  padding: 12,
  fontSize: 16,
})

const ErrorText = styled.div({
  marginTop: 12,
  color: 'red',
})

const Login = () => {
  const { loginUser } = useIdentityContext()
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState('')

  const handleSubmit = e => {
    if (e.key === 'Enter') {
      setError(<Spinner />)

      loginUser('janice.l.foo@gmail.com', password, true)
        .then(user => {
          console.log('Success! Logged in', user)
          navigate(window.location.pathname)
        })
        .catch(handleError)
    }
  }

  const handleError = () => {
    setError('Incorrect password')
    
    setPassword('')
    setTimeout(() => setError(''), 2000)
  }

  return (
    <Page>
      <Form>
        <div>
          <PasswordInput
            value={password}
            autoFocus
            type='password'
            placeholder='password'
            onChange={e => setPassword(e.currentTarget.value)}
            onKeyPress={handleSubmit}
          />
        </div>

        <ErrorText>{error}</ErrorText>
      </Form>
    </Page>
  )
}

export default Login
