export default [
  {
    thumbnailFile: 'nyoutritious-thumbnail.jpeg',
    imageFiles: [
      {
        file: 'nyoutritious-1.gif',
        customStyles: { 
          maxWidth: 325,
        },
      },
      {
        file: 'nyoutritious-2.jpeg',
        customStyles: { 
          maxWidth: 575,
          marginBottom: 120,
        },
      },
      {
        file: 'nyoutritious-3.png',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'nyoutritious-4.png',
        customStyles: { 
          maxWidth: 950,
          marginBottom: -20,
        },
      },
      {
        file: 'nyoutritious-5.png',
        customStyles: { 
          maxWidth: 950,
        },
      },
    ],
    thumbnailLabel: 'Nyoutritious',
    pageTitle: 'Nyoutritious',
    pageBlurb: 'Nyoutritious is a self-initiated project inspired by my recent resolution (for real this time!) to eat healthier. This mobile app helps track and record each item you consume. It also calculates your nutrition intake and determines how many more grams of grains, fiber, protein, iron, etc. you should consume to reach the recommended amount for your age, gender, and health history.',
  },
  {
    thumbnailFile: 'recipe-collect-thumbnail.jpeg',
    imageFiles: [
      {
        file: 'recipe-collect-1.png',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'recipe-collect-2.jpg',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'recipe-collect-3.jpeg',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'recipe-collect-4.jpeg',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'recipe-collect-5.jpeg',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'recipe-collect-6.jpeg',
        customStyles: { 
          maxWidth: 950,
        },
      },
    ],
    thumbnailLabel: 'RecipeCollect',
    pageTitle: 'RecipeCollect',
    pageBlurb: 'RecipeCollect is a recipe-saving app where users can collect all their favorite recipes as well as discover new recipes and save them into different categories. A search bar allows users to conveniently search the entire recipe database. Users can also use the comments section to comment on attempted recipe variations, what they plan on trying next, etc. The homepage refreshes daily with new ideas and promotions.',
  },
  {
    thumbnailFile: 'gin-thumbnail.jpg',
    imageFiles: [
      {
        file: 'gin-1.jpg',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'gin-2.jpg',
        customStyles: { 
          maxWidth: 950,
        },
      },
    ],
    thumbnailLabel: 'Natura Gin',
    pageTitle: 'Natura Gin',
    pageBlurb: '',
  },
  {
    thumbnailFile: 'sigma-thumbnail.jpeg',
    imageFiles: [
      {
        file: 'sigma-1.jpeg',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'sigma-2.jpeg',
        customStyles: { 
          maxWidth: 950,
        },
      },
      { 
        file: 'sigma-3.gif', 
        customStyles: { 
          maxWidth: 400 
        },
      },
      { 
        file: 'sigma-4.png', 
        customStyles: { 
          maxWidth: 1300,
          margin: '0 auto 50px',
        }, 
      },
      { 
        file: 'sigma-5.png', 
        customStyles: { 
          maxWidth: 950,
        }, 
      },
    ],
    thumbnailLabel: 'Sigma Eyecare',
    pageTitle: 'Sigma Eyecare',
    pageBlurb: 'Sigma Eyecare is a self-initiated app and web project created in Figma. The platform helps patients compare and locate eye doctors, and allows them to chat online with their doctors about their medical needs.',
  },
  {
    thumbnailFile: 'chorus-thumbnail.jpg',
    imageFiles: [
      {
        file: 'chorus-1.png',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'chorus-2.jpg',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'chorus-3.jpg',
        customStyles: { 
          maxWidth: 950,
          margin: '0 auto 24px',
        },
      },
      {
        file: 'chorus-4.png',
        customStyles: { 
          maxWidth: 1065,
        },
      },
      {
        file: 'chorus-5.jpg',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'chorus-6.png',
        customStyles: { 
          maxWidth: 1070,
        },
      },
    ],
    thumbnailLabel: 'Chorus',
    pageTitle: 'Chorus',
    pageBlurb: 'Chorus is a web and mobile app that allows users to upload their favorite songs or download from Chorus’s library. Users can easily create different playlists as well as search for new music based on their preferences.',
  },
  {
    thumbnailFile: 'wedding-web-cover.gif',
    imageFiles: [
      {
        file: 'wedding-web-cover.gif',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'wedding-home-on-macbook.gif',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'wedding-2.png',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'wedding-3.png',
        customStyles: { 
          maxWidth: 700,
          margin: '36px auto 64px',
        },
      },
      {
        file: 'wedding-4.gif',
        customStyles: { 
          maxWidth: 950,
        },
      },
    ],
    thumbnailLabel: 'Wedding Website',
    pageTitle: 'Wedding Website',
    pageBlurb: 'Unlike the conventional, “romantic” wedding website featuring a minimal, white background, this wedding website hopes to give off a fun, laidback vibe. Its design style encourages users to explore and click around, while still being straightforward, functional, and informative.',
  },
  {
    thumbnailFile: 'covid-19-thumbnail.jpeg',
    imageFiles: [
      {
        file: 'covid-19-1.gif',
        customStyles: { 
          maxWidth: 1130,
          marginBottom: 0,
        },
      },
      {
        file: 'covid-19-2.jpg',
        customStyles: { 
          maxWidth: 1100,
        },
      },
      {
        file: 'covid-19-3.jpg',
        customStyles: { 
          maxWidth: 1100,
        },
      },
      {
        file: 'covid-19-4.jpg',
        customStyles: { 
          maxWidth: 1100,
        },
      },
      {
        file: 'covid-19-5.jpg',
        customStyles: { 
          maxWidth: 1100,
        },
      },
      {
        file: 'covid-19-6.jpg',
        customStyles: { 
          maxWidth: 1100,
        },
      },
    ],
    thumbnailLabel: 'COVID-19 Dashboard',
    pageTitle: 'COVID-19 Dashboard',
    pageBlurb: 'The COVID-19 dashboard gives users an overview of the statistics regarding the number of cases, recoveries, and fatalities of COVID-19 grouped by region.',
  },
  {
    thumbnailFile: 'elephant-thumbnail.jpg',
    imageFiles: [
      {
        file: 'elephant-1.png',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'elephant-2.jpg',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'elephant-3.png',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'elephant-4.jpg',
        customStyles: { 
          maxWidth: 950,
        },
      },
      {
        file: 'elephant-5.gif',
        customStyles: { 
          maxWidth: 550,
        },
      },
    ],
    thumbnailLabel: 'White Elephant App',
    pageTitle: 'White Elephant App',
    pageBlurb: '',
  }
]
