import React, { useState } from 'react'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'

import { mobile, iphoneSe } from './shared/mediaQueries'

const BarContainer = styled.div({
  position: 'sticky',
  top: 0,
  display: 'flex',
  padding: '40px 60px',
  backgroundColor: 'white',
  zIndex: 1,
  [mobile]: {
    padding: '24px 12px',
  },
})

// https://stackoverflow.com/a/33856609/10957842
const BarContainerSection = styled.div({ 
  display: 'flex', 
  flex: 1, 
  justifyContent: 'center' 
})

const SideLink = styled(NavLink)({
  color: '#A09E9E',
  fontSize: 14,
  [mobile]: {
    display: 'none'
  },
})

const SlidingPanelSideLink = styled(NavLink)({
  fontSize: 20,
  paddingRight: 50,
  ':first-of-type': {
    marginTop: 80,
  },
  ':not(:last-child)': {
    marginBottom: 24,
  },
  ':hover': {
    opacity: 0.6,
  },
})

const CenterLink = styled(NavLink)({
  fontWeight: 'bold',
  fontSize: 22,
  lineHeight: '27px',
  whiteSpace: 'nowrap',
})

const Hamburger = styled.div({
  display: 'none',
  cursor: 'pointer',
  alignSelf: 'baseline',
  position: 'absolute',
  fontSize: 20,
  right: 24,
  top: 24,
  userSelect: 'none',
  ':hover': {
    opacity: 0.6,
  },
  [mobile]: {
    display: 'inline',
  },
  [iphoneSe]: {
    fontSize: 16,
  }
})

const SlidingPanel = styled.div(
  {
    display: 'none',
    position: 'fixed',
    top: 0,
    zIndex: 2,
    width: '50vw',
    minWidth: 182,
    height: '100vh',
    backgroundColor: '#f0eeee',
    transition: '350ms',
    boxShadow: '-15px 0 10px rgba(0,0,0,0.08)',
    borderRadius: '5px 0 0 5px',
    [mobile]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  ({ open }) => ({
    right: open ? 0 : '-65vw',
    // target the sibling element (div with blurb and thumbnails inside)
    // to prevent it from being able to scroll while panel is open; 
    // this setup allows that element to preserve its scroll position;
    '& + div': { overflow: open ? 'hidden' : 'auto' },
  })
)

const NavBar = () => {
  let [open, toggleModal] = useState(false)

  return (
    <>
      <BarContainer>
        <BarContainerSection>
          <span style={{ marginRight: 'auto' }}>
            <SideLink style={{ marginRight: 24 }} to="/work">Work</SideLink>
            <SideLink to="/motion">Motion</SideLink>
          </span>
        </BarContainerSection>

        <BarContainerSection>
          <CenterLink to="/work">JANICE FOO</CenterLink>
        </BarContainerSection>

        <BarContainerSection>
          <span style={{ marginLeft: 'auto' }}>
            <SideLink to="/about" style={{ marginLeft: 'auto' }}>About</SideLink>
          </span>
        </BarContainerSection>

        <Hamburger onClick={() => toggleModal(!open)}>
          ☰
        </Hamburger>
      </BarContainer>

      <SlidingPanel open={open}>
        <Hamburger onClick={() => toggleModal(!open)}>
          ✕
        </Hamburger>

        <SlidingPanelSideLink 
          to="/work" 
          onClick={() => toggleModal(false)}
        >
          Work
        </SlidingPanelSideLink>

        <SlidingPanelSideLink 
          to="/motion" 
          onClick={() => toggleModal(false)}
        >
          Motion
        </SlidingPanelSideLink>

        <SlidingPanelSideLink 
          to="/about" 
          onClick={() => toggleModal(false)}
        >
          About
        </SlidingPanelSideLink>
      </SlidingPanel>
    </>
  )
}

export default NavBar
