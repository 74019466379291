import React, { useContext } from 'react'
import styled from '@emotion/styled'
import _ from 'lodash'

import Img from 'gatsby-image'
import { NavLink } from 'react-router-dom'

import GatsbyImagesContext from '../../../GatsbyImagesContext'
import { bigTablet, tablet, mobile } from '../../../shared/mediaQueries'

const ThumbnailImage = styled.img({
  objectFit: 'contain',
  width: '100%',
  height: '100%',
})

const ThumbnailContainer = styled.div({
  position: 'relative'
})

const ImageOverlay = styled.div(
  {
    top: 0,
    left: 0,
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'transparent',
    transition: '250ms',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }, 
  ({ thumbnailLabel }) => ({
    ':hover': {
      background: 'rgba(255, 255, 255, 0.75)',
      '::after': {
        content: `'${thumbnailLabel}'`,
        fontSize: 29,
        fontWeight: 'bold',
        [bigTablet]: {
          fontSize: 20,
        },
        [tablet]: {
          fontSize: 25,
        },
        [mobile]: {
          fontSize: 16,
        },

      },
    },
  })
)

const Thumbnail = ({ 
  thumbnailFile, 
  thumbnailLabel,
  pageTitle,
}) => {
  const gatsbyImageObjectsMap = useContext(GatsbyImagesContext);

  return (
    <NavLink to={'/work/' + _.kebabCase(pageTitle)}>
      <ThumbnailContainer>
        {
          thumbnailFile in gatsbyImageObjectsMap
            ? (
              <Img
                key={thumbnailFile}
                fluid={gatsbyImageObjectsMap[thumbnailFile]}
              />
            )
            : (
              <ThumbnailImage
                src={require('../../../../../assets/' + thumbnailFile)}
                alt={thumbnailLabel}
              />
            )
        }
        <ImageOverlay thumbnailLabel={thumbnailLabel} />
      </ThumbnailContainer>
    </NavLink>
  )
}

export default Thumbnail
