import React from 'react'
import { Router } from '@reach/router'
import App from '../components/App'
import Layout from '../components/Layout'

export default () => (
  <Layout>
    <Router basepath='/'>
      <App path='/*' />
    </Router>
  </Layout>
)
