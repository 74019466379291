import React from 'react'
import { useIdentityContext } from 'react-netlify-identity-widget'
import Login from './Login'
import Home from './Home'

const App = () => {
  const { isLoggedIn } = useIdentityContext()

  if (!isLoggedIn) {
    return <Login />
  }

  return <Home />
}

export default App
