import React from 'react'
import { Switch, Route } from 'react-router-dom'
import _ from 'lodash'

import ProjectPage from './ProjectPage'
import Overview from './Overview'

import assetsConfig from './assets-config'

const Work = () => (
  <Switch>
    {
      assetsConfig.map(({ imageFiles, pageTitle, pageBlurb }) => (
        <Route
          key={pageTitle}
          path={'/work/' + _.kebabCase(pageTitle)}
          render={props => (
            <ProjectPage
              {...props}
              imageFiles={imageFiles}
              title={pageTitle}
              blurb={pageBlurb}
            />
          )}
        />
      ))
    }

    <Route 
      path='/' 
      render={props => (
        <Overview 
          {...props} 
        />
      )} 
    />
  </Switch>
)

export default Work
