import styled from '@emotion/styled'

import { mobile } from './mediaQueries'

const BigBlurb = styled.div({
  fontWeight: 'bold',
  fontSize: 27,
  lineHeight: '39px',
  textAlign: 'center',
  padding: '24px 100px 48px',
  [mobile]: {
    fontSize: 20,
    lineHeight: '20px',
    padding: '12px 12px 24px',
  },
})

export default BigBlurb
