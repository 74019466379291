import React, { useContext } from 'react'
import path from 'path'
import { Img as ReactImg } from 'react-image'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

import GatsbyImagesContext from '../GatsbyImagesContext'
import Video from '../shared/Video'
import BigBlurb from '../shared/BigBlurb'
import MediumBlurb from '../shared/MediumBlurb'
import GinMp4 from '../../../assets/gin.mp4'

import { tablet, mobile } from '../shared/mediaQueries'

const ImagesList = styled.div({
  '& > :not(:last-child)': {
    marginBottom: 48,
  },
  [tablet]: {
    '& > :not(:last-child)': {
      marginBottom: 32,
    }
  },
  [mobile]: {
    '& > :not(:last-child)': {
      marginBottom: 16,
    },
  },
  paddingBottom: 60,
})

const GifImage = styled(ReactImg)({
  display: 'block',
  margin: '0 auto',
  width: '100%',
  padding: '0 12px',
})

const AnchorLink = styled.a({
  ':hover': {
    color: 'lightblue'
  }
})

const ProjectPage = ({ imageFiles, title, blurb }) => {
  const gatsbyImageObjectsMap = useContext(GatsbyImagesContext)

  return (
    <div>
      <BigBlurb>
        {title}
      </BigBlurb>

      <MediumBlurb>
        {blurb}

        {
          title === 'White Elephant App' && (
            <AnchorLink 
              target='_blank'
              href='https://elephant.gift'
            >
              Live link
            </AnchorLink>
          )
        }
      </MediumBlurb>

      {
        title === 'Natura Gin' && <Video src={GinMp4} />
      }

      <ImagesList>
        {
          imageFiles.map(({ file, customStyles }) => (
            file in gatsbyImageObjectsMap
              ? (
                <Img 
                  key={file} 
                  fluid={gatsbyImageObjectsMap[file]} 
                  style={{ margin: '0 auto', ...customStyles }}
                />
              )
              : (
                <GifImage
                  key={file}
                  src={require(`../../../assets/${file}`)}
                  alt={file}
                  style={customStyles}
                  loader={(
                    <Img
                      fluid={gatsbyImageObjectsMap[`${path.basename(file, '.gif')}_PLACEHOLDER.jpg`]}
                      style={{ margin: '0 auto', ...customStyles }}
                    />
                  )}
                />
              )
          ))
        }
      </ImagesList>
    </div>
  )
}

export default ProjectPage
