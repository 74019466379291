import styled from '@emotion/styled'

import { mobile } from './mediaQueries'

const MediumBlurb = styled.div({
  fontSize: 20,
  lineHeight: '27px',
  textAlign: 'center',
  padding: '0 170px 48px',
  maxWidth: 1200,
  margin: '0 auto',
  [mobile]: {
    fontSize: 16,
    lineHeight: '20px',
    padding: '0 24px 24px',
  },
})

export default MediumBlurb
