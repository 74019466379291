import React, { useContext } from 'react'
import { Img as ReactImg } from 'react-image'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

import BigBlurb from './shared/BigBlurb'
import Video from './shared/Video'

import GatsbyImagesContext from './GatsbyImagesContext'

import { tablet, mobile } from './shared/mediaQueries'

import ShapesGif from '../../assets/shapes-1.gif'
import FarmRichMp4 from '../../assets/fr-1.mp4'
import AllMp4 from '../../assets/all-1.mp4'
import AllPlaceholder from '../../assets/all-1_PLACEHOLDER.png'
import EgglandsMp4 from '../../assets/eb-1.mp4'
import KittyAnnGif from '../../assets/kitty-ann.gif'
import DunkinGif from '../../assets/dunkin-1.gif'

const ImagesList = styled.div({
  '& > :not(:last-child)': {
    marginBottom: 48,
  },
  [tablet]: {
    '& > :not(:last-child)': {
      marginBottom: 32,
    }
  },
  [mobile]: {
    '& > :not(:last-child)': {
      marginBottom: 16,
    },
  },
  paddingBottom: 60,
})

const GifImage = styled(ReactImg)({
  display: 'block',
  margin: '0 auto',
  width: '100%',
  padding: '0 12px',
  maxWidth: 950,
})

const Motion = () => {
  const gatsbyImageObjectsMap = useContext(GatsbyImagesContext)

  return (
    <div>
      <BigBlurb />
      
      <ImagesList>
        <GifImage 
          src={ShapesGif} 
          loader={(
            <Img
              fluid={gatsbyImageObjectsMap['shapes-1_PLACEHOLDER.jpg']}
              style={{ margin: '0 auto', maxWidth: 950 }}
            />
          )}
        />
        <GifImage 
          src={DunkinGif}
          loader={(
            <Img
              fluid={gatsbyImageObjectsMap['dunkin-1_PLACEHOLDER.jpg']}
              style={{ margin: '0 auto', maxWidth: 950 }}
            />
          )}
        />
        <Video 
          src={AllMp4} 
          otherVideoProps={{ poster: AllPlaceholder, preload: 'none' }} 
        />
        <Video src={FarmRichMp4} />
        <Video src={EgglandsMp4} />
        <GifImage 
          src={KittyAnnGif} 
          loader={(
            <Img
              fluid={gatsbyImageObjectsMap['kitty-ann_PLACEHOLDER.jpg']}
              style={{ margin: '0 auto', maxWidth: 950 }}
            />
          )}
        />
      </ImagesList>
    </div>
  )
}

export default Motion
