import React from 'react'

const Video = ({ src, otherVideoProps }) => (
  <div>
    <video 
      disablePictureInPicture 
      controls 
      controlsList="nodownload"
      style={{  
        display: 'block', 
        maxWidth: 950, 
        margin: '0 auto',
        width: '100%',
        padding: '0 12px',
        boxSizing: 'border-box',
      }}
      {...otherVideoProps}
    >
      <source src={src} type="video/mp4" />
    </video>
  </div>
)

export default Video
