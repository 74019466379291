import React from 'react'
// import styled from '@emotion/styled'

import BigBlurb from '../../shared/BigBlurb'
import Thumbnails from './Thumbnails'

// const OverviewContainer = styled.div({
//   overflow: 'auto',
// })

const Overview = () => {
  return (
    <div>
      <BigBlurb>
        Janice is a designer passionate about creating effective and beautiful design, from branding and packaging to user interfaces.
      </BigBlurb>

      <Thumbnails />
    </div>
  )
}

export default Overview
