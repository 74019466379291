import React from 'react'
import styled from '@emotion/styled'

import BigBlurb from './shared/BigBlurb'
import MediumBlurb from './shared/MediumBlurb'

const EmailLink = styled.a({
  fontSize: 20,
  fontWeight: 'bold',
  textAlign: 'center',
  ':hover': {
    color: '#0668D9',
  },
})

const About = () => (
  <div>
    <BigBlurb>
      Passionate, experienced brand designer
    </BigBlurb>

    <MediumBlurb>
      <div style={{ maxWidth: 650, margin: '0 auto' }}>
        Janice is a skilled designer with six years of industry experience at a branding agency. She has worked on projects with large consumer brands such as Disney, Pfizer, Henkel, B&G Foods, Hormel, and Rich Products. Janice uses the Adobe Creative Suite daily and is an expert in Photoshop and Illustrator. She is also proficient in using After Effects and Figma. In addition, she has extensive experience designing social media posts, digital and print ads, and other marketing materials.
        <br />
        <br />
        In her spare time, Janice peruses design blogs and is incredibly excited about following graphic and web design trends. She continues to hone her skills by delving deeper into the design programs she already uses as well as learning new ones. She greatly enjoys creating compelling visual designs.
      </div>
    </MediumBlurb>

    <div style={{ paddingBottom: 60 }}>
      <EmailLink href="mailto:janicefoodesign@gmail.com" target="_blank">
        <div>janicefoodesign@gmail.com</div>
      </EmailLink>
    </div>
  </div>
)

export default About
