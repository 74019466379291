import React from 'react'
import styled from '@emotion/styled'
import { 
  Switch, 
  Route, 
  Redirect, 
  BrowserRouter as Router 
} from 'react-router-dom'

import { useStaticQuery } from 'gatsby'
import GatsbyImagesContext from './GatsbyImagesContext'

import ScrollToTop from './shared/ScrollToTop'
import NavBar from './NavBar'
import Work from './Work'
import About from './About'
import Motion from './Motion'

const HomeContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
})

const Home = () => {
  const data = useStaticQuery(graphql`
    query GetProjectImages {
      allFile(filter: { sourceInstanceName: {eq: "images"} }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1000) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const gatsbyImageObjectsMap = data.allFile.edges.reduce((acc, { node: { childImageSharp } }) => {
    if (childImageSharp) {
      acc[childImageSharp.fluid.originalName] = childImageSharp.fluid
    }

    return acc
  }, {})

  return (
    <GatsbyImagesContext.Provider value={gatsbyImageObjectsMap}>
      <HomeContainer>
        <Router>
          <ScrollToTop>
            <NavBar />
          
            <Switch>
              <Route path="/work" component={Work} />
              <Route path="/about" component={About} />
              <Route path="/motion" component={Motion} />
              <Redirect to="/work" />
            </Switch>
          </ScrollToTop>
        </Router>
      </HomeContainer>
    </GatsbyImagesContext.Provider>
  )
}

export default Home
