import React from 'react'
import styled from '@emotion/styled'

import Thumbnail from './Thumbnail'

import assetsConfig from '../../assets-config'

import { tablet, mobile } from '../../../shared/mediaQueries'

const OuterGrid = styled.div({
  display: 'grid',
  gridTemplateColumns: '100px 1fr 1fr 100px',
  gridTemplateRows: '1fr 60px',
  gridTemplateAreas: `
    'x t t y'
    'b b b b'
  `,
  [mobile]: {
    gridTemplateColumns: '12px 1fr 1fr 12px',
  },
})

const InnerGrid = styled.div({
  gridArea: 't',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: 35,
  [tablet]: {
    gridTemplateColumns: '1fr',
    gridGap: 18,
  },
})

const Thumbnails = () => {
  return (
    <OuterGrid>
      <InnerGrid>
        {
          assetsConfig.map(({ thumbnailFile, thumbnailLabel, pageTitle }) => {
            return (
              <Thumbnail 
                key={thumbnailLabel}
                thumbnailFile={thumbnailFile}
                thumbnailLabel={thumbnailLabel}
                pageTitle={pageTitle}
              />
            )
          })
        }
      </InnerGrid>
    </OuterGrid>
  )
}

export default Thumbnails
